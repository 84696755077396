<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import axios from "axios";
import {paginationHelper} from "@/helpers/pagination-helper";
import {jsonUtil} from "@/helpers/json-util";
import Swal from "sweetalert2";
import {errorCatcher} from "@/helpers/error-catcher";
import EcatLightboxGallery from "@/components/ecat-lightbox-gallery.vue";
import {productsHelper} from "@/helpers/products-helper";
import {productHelper} from "@/helpers/product-helper";
import ProductDetailsModal from "@/components/product/product-details-modal.vue";
import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";
import ChangeOversizeModal from "@/components/product/change-oversize-modal.vue";
import {priceHelper} from "@/helpers/price-helper";
import Switches from "vue-switches";

export default {

  components: {
    ChangeOversizeModal,
    CustomCardSubtitle,
    ProductDetailsModal,
    EcatLightboxGallery,
    Layout,
    Switches,
    PageHeader
  },

  data() {
    return {
      submitted: false,
      depot: null,
      depotId: "",

      depotSectionStocks: [],

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100, 500, 1000],
        filter: "",
        filterOn: [],
        sortBy: "name",
        sortDesc: false
      },

      form: {
        productIds: []
      },

      products: [],
      isBusy: false,

      categories: null,
      taxes: null,

      showModal: false,

      selectedProductImage: {
        visible: false,
        images: []
      },

      activeAccount: null,

      inputPage: '',

      tableColumns: {
        visibleModal: false,

        sku: true,
        image: true,
        // warehouseProduct: true,
        name: true,
        categoryName: true,
        ean: true,
        shopProfit: true,
        ecatProfit: true,
        logistics: true,
        profitMargin: true,
        priceLow: true,
        priceHigh: true,
        priceWholesaleHigh: true,
        expect: true,
        blockedOnAllegro: true,
        totalWarehouseStock: true,
        warehouse: true,
        warehouseEcat: true,
        tax: true,
        deliveryTime: true,
        shippingCost: true,
        industry: true
      },

      filtering: {
        showProductsWithEAN: false,
        bestSellingProducts: false,
        showNotAvailableProducts: false,
        sku: "",
        ean: "",
        // warehouseProduct: "",
        name: "",
        priceMin: "",
        priceMax: "",
        warehouseId: "",
        categoryId: "",
        industryId: "",
        attributeGroupId: "",
        attribute: "",
        depotSectionId: ""
      },

      warehouses: null,
      industries: null,
      shippingCosts: null,
      bestSellingProductsIds: [],
      deliveryUnitsMap: null,
      orderByMap: new Map([
        ["sku", "product_variation.sku"],
        ["priceHigh", "product.price_high"],
        ["productPriceWholesaleHigh", "product.price_wholesale_high"],
        ["totalWarehouseStock", "product.total_warehouse_stock"],
        ["tax", "product.tax_id"],
      ])
    };
  },

  methods: {

    async changeOversize() {
      const json = {
        ids: this.form.productIds,
        oversizeType: this.$refs.changeOversizeModal.getOversizeType()
      }

      try {
        await axios.post(`/product/internal/identification/update-oversize/by/product-ids`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        })

        this.form.productIds = []
        Swal.fire("Sukces!", 'Pomyślnie zmieniono status gabarytu dla wybranych produktów', "success").then(() => {
          this.$refs.table.refresh()
        });
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    setToFirstPageAndRefresh() {
      this.table.currentPage = 1
      this.$refs.table.refresh()
    },

    async loadShippingCosts() {
      try {
        const productIds = [];
        for (let product of this.products) {
          productIds.push(product.productId);
        }

        const json = JSON.stringify({
          "productIds": productIds
        });

        const { data } = await axios.post(`/product/target-shipping-costs`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        if (data && data.data) {
          this.shippingCosts = new Map()

          data.data.forEach(item => {
            const productId = item.productId;
            const carrierData = {
              carrierId: item.carrierId,
              allegroCarrierId: item.allegroCarrierId,
              cost: item.cost,
              name: item.name
            };

            if (this.shippingCosts.has(productId)) {
              this.shippingCosts.get(productId).push(carrierData);
            } else {
              this.shippingCosts.set(productId, [carrierData]);
            }
          });
        }
      } catch (error) {
        console.log(error)
      }
    },

    getShippingCost(productId) {
      if (!this.shippingCosts || this.shippingCosts.size === 0) {
        return null;
      }

      return this.shippingCosts.get(productId) || null;
    },

    async loadWarehouses() {
      try {
        if (this.products.length === 0) {
          return;
        }

        const ids = [];
        for (let product of this.products) {
          ids.push(product.warehouseId);
        }

        const json = JSON.stringify({
          "ids": ids
        });

        const result = await axios.post(`/warehouse/fetch`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.warehouses = result.data
        await this.loadIndustries()
      } catch (error) {
        console.log(error)
      }
    },

    async loadIndustries() {
      try {
        if (this.warehouses.length === 0) {
          return;
        }

        const ids = [];
        for (let warehouse of this.warehouses) {
          ids.push(warehouse.industryId);
        }

        const json = JSON.stringify({
          "ids": ids
        });

        const result = await axios.post(`/industry/fetch`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.industries = new Map(result.data.map((obj) => [obj.id, obj]));
      } catch (error) {
        console.log(error)
      }
    },

    getWarehouse(id) {
      if (!this.warehouses) {
        return null
      }

      for (const warehouse of this.warehouses) {
        if (warehouse.id === id) {
          return warehouse
        }
      }

      return {}
    },

    getIndustry(id) { // TODO: make helper
      if (!this.industries) {
        return null
      }

      const industry = this.industries.get(id)
      return industry ? industry : null;
    },

    changeFilterShowProductsWithEan() {
      this.filtering.showProductsWithEAN = !this.filtering.showProductsWithEAN
      this.$refs.table.refresh()
    },

    changeBestSellingProducts() {
      this.filtering.bestSellingProducts = !this.filtering.bestSellingProducts;
      this.$refs.table.refresh()
    },

    clearFilters() {
      this.filtering.showProductsWithEAN = false
      this.filtering.bestSellingProducts = false
      this.filtering.showNotAvailableProducts = false
      this.filtering.sku = ""
      this.filtering.ean = ""
      this.filtering.warehouseProduct = ""
      this.filtering.name = ""
      this.filtering.priceMin = ""
      this.filtering.priceMax = ""
      this.filtering.warehouseId = ""
      this.filtering.categoryId = ""
      this.filtering.industryId = ""
      this.filtering.attributeGroupId = ""
      this.filtering.depotSectionId = ""
      this.setToFirstPageAndRefresh()
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('depot-products.title', { name: this.depot?.name || '' }),
          active: true
        }
      ]
    },

    getFields() {
      const fields = []
      fields.push({key: 'element', label: ""})

      if (this.tableColumns.sku) {
        fields.push({key: 'sku', label: "SKU", formatter: value => "W" + value})
      }

      if (this.tableColumns.image) {
        fields.push({key: "image", label: this.$t('table.image')})
      }

      // if (this.tableColumns.warehouseProduct) {
      //   fields.push({key: "warehouseProduct", sortable: true, label: 'Index Hurtowni'})
      // }

      if (this.tableColumns.name) {
        fields.push({key: "name", label: this.$t('message.name')})
      }

      if (this.tableColumns.categoryName) {
        fields.push({key: "categoryName", label: this.$t('table.category')})
      }

      if (this.tableColumns.ean) {
        fields.push({key: "ean", label: 'EAN'})
      }

      if (this.tableColumns.profitMargin) {
        fields.push({key: "warehouseProfitMargin", label: this.$t('table.margin'), formatter: productsHelper.profitMarginFormatter})
      }

      if (this.tableColumns.shopProfit) {
        fields.push({key: "shopProfit", label: 'Zysk sklepu', formatter: (value, key, item) => productsHelper.profitFormatter(value, key, item, item.taxRate) })
      }

      if (this.tableColumns.ecatProfit) {
        fields.push({key: "ecatProfit", label: 'Zysk ECAT eCommerce (Full Dropshipping)', formatter: (value, key, item) => productsHelper.ecatProfitFormatter(value, key, item, item.taxRate) })
      }

      if (this.tableColumns.logistics) {
        fields.push({key: "logistics", label: 'Koszty logistyki i księgowości', formatter: (value, key, item) => productsHelper.logisticsFormatter(value, key, item, item.taxRate) })
      }

      if (this.tableColumns.priceHigh) {
        fields.push({key: "priceHigh", sortable: true, label: this.$t('table.price-high'), formatter: productsHelper.priceHighFormatter})
      }

      if (this.tableColumns.priceLow) {
        fields.push({key: "priceLow", label: "Cena sklepu netto", formatter: (value, key, item) => productsHelper.priceLowFormatter(value, key, item, item.taxRate) })
      }

      if (this.tableColumns.priceWholesaleHigh) {
        fields.push({
          key: "productPriceWholesaleHigh",
          sortable: true,
          label: this.$t('table.price-wholesale-high'),
          formatter: value => priceHelper.format(value, this.$store.getters["market/currentCurrency"])
        })
      }

      if (this.tableColumns.expect) {
        fields.push({key: "expect", label: this.$t('products.table.expect')})
      }

      // if (this.tableColumns.blockedOnAllegro) {
      //   fields.push({key: "blockedOnAllegro", label: "Czy zablokowana wysyłka na Allegro"})
      // }

      if (this.tableColumns.totalWarehouseStock) {
        fields.push({key: "productTotalWarehouseStock", slot: true, sortable: true, label: this.$t('table.total-warehouse-stock')})
      }

      if (this.tableColumns.warehouse) {
        fields.push({key: "warehouse", label: this.$t('products.table.warehouse')})
      }

      if (this.tableColumns.warehouseEcat) {
        fields.push({key: "warehouseEcat", slot: true, label: "Magazyn ECAT"})
      }

      if (this.tableColumns.tax) {
        fields.push({key: "tax", sortable: true, label: this.$t('products.table.tax-rate')})
      }

      if (this.tableColumns.deliveryTime) {
        fields.push({key: "deliveryTime", label: this.$t('products.table.delivery-time')})
      }

      if (this.tableColumns.industry) {
        fields.push({key: "industryName", label: 'Branża'})
      }

      if (this.tableColumns.shippingCost) {
        fields.push({key: "shippingCost", label: 'Koszt dostawy'})
      }

      fields.push({key: "action", label: this.$t('table.actions')})
      return fields
    },

    async loadProducts() {
      if (this.filtering.bestSellingProducts) {
        await this.loadBestSellingProducts()
      }

      const selectedMarket = this.$store.getters["market/selectedMarket"];
      if (!selectedMarket) {
        return
      }

      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        let orderBy = {}
        if (this.table.sortBy) {
          const orderByElement = this.orderByMap.get(this.table.sortBy)
          if (orderByElement) {
            orderBy = JSON.stringify({
              [orderByElement]: this.table.sortDesc ? "DESC" : "ASC"
            })
          }
        }

        const { data } = await axios.get(`/depot/product/list-depot-products`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            depotId: this.depotId,
            "page": page,
            "size": this.table.perPage,
            "showProductsWithEan": this.filtering.showProductsWithEAN,
            "showNotAvailableProducts": this.filtering.showNotAvailableProducts,
            "bestSellingProducts": this.filtering.bestSellingProducts,
            "filterByProductIds": this.bestSellingProductsIds.join(","),
            // "warehouseProduct": this.filtering.warehouseProduct,
            "sku": this.filtering.sku,
            "ean": this.filtering.ean,
            "name": this.filtering.name,
            "priceMin": this.filtering.priceMin ? this.filtering.priceMin : null,
            "priceMax": this.filtering.priceMax ? this.filtering.priceMax : null,
            "warehouseId": this.filtering.warehouseId,
            "categoryId": this.filtering.categoryId,
            "industryId": this.filtering.industryId,
            "filter": this.table.filter,
            "attributeGroupId": this.filtering.attributeGroupId,
            "attributeValue": this.filtering.attribute,
            "marketId": selectedMarket.id,
            "orderBy": orderBy,
            depotSectionId: this.filtering.depotSectionId
          }
        });

        this.products = data.productVariations
        this.table.totalRows = data.count
        this.table.rows = data.count

        await this.loadWarehouses()
        await this.loadShippingCosts()
        await this.loadDepotSectionStocks()

        return this.products;
      } catch (error) {
        console.log(error)
        return []
      }
    },

    async loadDepotSectionStocks() {
      try {
        const ids = this.products.map(product => product.id);
        const json = {
          productVariationIds: ids
        }

        const {data} = await axios.post(`/depot/section/stock/product-variation-stock-summary`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.depotSectionStocks = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getDepotSectionStocks(productVariationId) {
      return this.depotSectionStocks.filter(element => element.productVariationId === productVariationId)
    },

    getDepotSectionStockDepots(productVariationId) {
      const uniqueDepotIds = new Map();
      const uniqueStocks = [];

      for (const element of this.depotSectionStocks) {
        if (element.productVariationId === productVariationId) {
          if (!uniqueDepotIds.has(element.depotId)) {
            uniqueDepotIds.set(element.depotId, true);
            uniqueStocks.push(element);
          }
        }
      }

      return uniqueStocks;
    },

    getSumDepotSectionStocks(productVariationId) {
      return this.depotSectionStocks
          .filter(element => element.productVariationId === productVariationId)
          .reduce((suma, element) => suma + element.quantity, 0);
    },

    async loadBestSellingProducts() {
      try {
        const { data } = await axios.get(`/product/best-selling`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        await this.loadProductVariationsByIds(data)
      } catch (error) {
        console.log(error)
      }
    },

    async loadProductVariationsByIds(bestSellingProducts) {
      try {
        const ids = [];
        for (const bestSellingProduct of bestSellingProducts) {
          ids.push(bestSellingProduct.productVariationId);
        }

        const json = JSON.stringify({
          "ids": ids
        });

        const result = await axios.post(`/product/variation/list-by-ids`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        const bestSellingProductsIds = [];
        for (let productVariation of result.data) {
          bestSellingProductsIds.push(productVariation.productId);
        }

        this.bestSellingProductsIds = bestSellingProductsIds
      } catch (error) {
        console.log(error)
      }
    },

    getShopName(id) {
      for (const shop of this.shops) {
        if (shop.id === id) {
          return shop.name;
        }
      }

      return "";
    },

    getTime(value) {
      const humanizeDuration = require("humanize-duration");
      return humanizeDuration(value, { language: this.$store.getters["translation/getCurrentLocaleOrFallback"] });
    },

    refreshSlidebar() {
      this.$refs.slidebar.refresh()
    },

    selectProductImages(images) {
      this.selectedProductImage.images = images
      this.selectedProductImage.visible = true
    },

    hideSelectedProductImage() {
      this.selectedProductImage.images = []
      this.selectedProductImage.visible = false
    },

    selectAllProducts() {
      const ids = this.products.map(product => product.id);
      const newProductIds = ids.filter(id => !this.form.productIds.includes(id));

      if (newProductIds.length > 0) {
        this.form.productIds.push(...newProductIds);
      } else {
        this.form.productIds = this.form.productIds.filter(id => !ids.includes(id))
      }
    },

    isSameProductIds() {
      if (this.products.length === 0) {
        return false
      }

      const ids = this.products.map(product => product.id);
      return ids.every(id => this.form.productIds.includes(id))
    },

    highlightRow(item) {
      if (!item) {
        return ''
      }

      return this.form.productIds.includes(item.id) ? 'highlighted-product' : ''
    },

    async loadDeliveryUnits() {
      try {
        const { data } = await axios.get(`/delivery-unit`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        this.deliveryUnitsMap = new Map(data.deliveryUnits.map((obj) => [obj.deliveryUnit, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getDeliveryUnit(unit) {
      if (!this.deliveryUnitsMap) {
        return null
      }

      return this.deliveryUnitsMap.get(unit) || null
    },

    getProductDeliveryTime(product) {
      if (product.deliveryTime && product.deliveryUnit) {
        const deliveryUnit = this.getDeliveryUnit(product.deliveryUnit)
        if (deliveryUnit) {
          return product.deliveryTime + " " + deliveryUnit.name
        }
      }

      const warehouse = this.getWarehouse(product.warehouseId)
      if (warehouse) {
        const deliveryUnit = this.getDeliveryUnit(warehouse.deliveryUnit)
        if (deliveryUnit) {
          return warehouse.deliveryTime + " " + deliveryUnit.name
        }
      }

      return this.$t('products.no-delivery-time')
    },

    async loadDepot() {
      this.depotId = this.$route.params.id

      try {
        const {data} = await axios.get(`/depot/${this.$route.params.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.depot = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    }

  },

  computed: {
    productHelper() {
      return productHelper
    },

    jsonUtil() {
      return jsonUtil
    },

    paginationHelper() {
      return paginationHelper
    }

  },

  async beforeMount() {
    await this.loadDepot()
  },

  async created() {
    try {
      await this.loadDeliveryUnits()
    } catch (error) {
      console.log(error)
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('depot-products.title', { name: depot?.name || '' })" :items="getItems()" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="button-items mb-2">
              <router-link to="/dashboard/core/depots" tag="a" class="btn btn-success">
                Wróć do listy hurtowni
              </router-link>
            </div>

            <custom-card-subtitle title="Filtrowanie"/>

            <div class="button-items mb-2">
              <a @click="tableColumns.visibleModal = true" class="btn btn-primary">
                Dostosuj kolumny
              </a>

              <a @click="changeFilterShowProductsWithEan" class="btn btn-primary">
                {{ filtering.showProductsWithEAN ? 'Pokaż wszystkie produkty' : 'Pokaż produkty posiadające EAN' }}
              </a>
            </div>

            <div>
              <a @click="changeBestSellingProducts" class="btn btn-primary mb-2">
                Pokaż produkty najlepiej sprzedające się w ciągu 30 dni: {{ filtering.bestSellingProducts ? 'Tak' : 'Nie' }}
              </a>
            </div>

            <div class="col-lg-2">
              <div class="d-flex flex-sm-column">
                <label class="font-size-13">
                  Pokaż wszystkie produkty (nawet historyczne):
                  {{ filtering.showNotAvailableProducts ? "Tak" : "Nie" }}
                </label>

                <switches :emit-on-mount="false" @input="setToFirstPageAndRefresh" v-model="filtering.showNotAvailableProducts"
                          class="my-0" color="primary"/>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-2 col-12">
                <label>Nazwa produktu</label>
                <input type="text" v-model="filtering.name" class="form-control" id="product-name" @keyup.enter="setToFirstPageAndRefresh" @input="setToFirstPageAndRefresh" />
              </div>

              <div class="col-lg-2 col-12">
                <label>SKU</label>
                <input type="text" v-model="filtering.sku" class="form-control" id="sku" @keyup.enter="setToFirstPageAndRefresh" @input="setToFirstPageAndRefresh" />
              </div>

<!--              <div class="col-lg-2 col-12">-->
<!--                <label>Index Hurtowni</label>-->
<!--                <input type="text" v-model="filtering.warehouseProduct" class="form-control" id="warehouseProduct" @keyup.enter="setToFirstPageAndRefresh" @input="setToFirstPageAndRefresh" />-->
<!--              </div>-->

              <div class="col-lg-2 col-12">
                <label>EAN</label>
                <input type="number" v-model="filtering.ean" class="form-control" id="ean" @keyup.enter="setToFirstPageAndRefresh" @input="setToFirstPageAndRefresh" />
              </div>

              <div class="col-12">
              </div>

              <div class="col-lg-2 col-12">
                <label>Cena minimalna</label>
                <input type="number" v-model="filtering.priceMin" class="form-control" id="price-min" @keyup.enter="setToFirstPageAndRefresh" @input="setToFirstPageAndRefresh" />
              </div>

              <div class="col-lg-2 col-12">
                <label>Cena maksymalna</label>
                <input type="number" v-model="filtering.priceMax" class="form-control" id="price-max" @keyup.enter="setToFirstPageAndRefresh" @input="setToFirstPageAndRefresh" />
              </div>

              <div class="col-lg-2 col-12">
                <ecat-multiselect
                    :set-value="filtering.warehouseId"
                    @change="value => filtering.warehouseId = value"
                    @select="setToFirstPageAndRefresh()"
                    save-id="id"
                    view-id="name"
                    label="Hurtownia"
                    reset-option-name="Wszystkie hurtownie"
                    placeholder="Wszystkie hurtownie"
                    fetch-one-url="/warehouse"
                    load-url="/warehouse/list/pagination"
                    query-url="/warehouse/by-name"
                    param="name"
                    :can-unselect="true"
                    :custom-params="{withProducts: true, industryId: filtering.industryId, marketId: $store.getters['market/getMarketId']}"
                />
              </div>

              <div class="col-lg-2 col-12">
                <ecat-multiselect
                    label="Kategoria"
                    :set-value="filtering.categoryId"
                    @change="value => filtering.categoryId = value"
                    @select="setToFirstPageAndRefresh()"
                    view-id="name"
                    save-id="id"
                    :can-unselect="true"
                    reset-option-name="Wszystkie kategorie"
                    placeholder="Wszystkie kategorie"
                    fetch-one-url="/category"
                    load-url="/category/list/pagination"
                    query-url="/category/by-name"
                    param="name"
                    :custom-params="{'withProducts': true}"
                />
              </div>

              <div class="col-lg-2 col-12">
                <ecat-multiselect
                    :set-value="filtering.industryId"
                    @change="value => filtering.industryId = value"
                    @select="setToFirstPageAndRefresh()"
                    :can-unselect="true"
                    save-id="id"
                    view-id="name"
                    :label="$t('message.industry')"
                    reset-option-name="Wszystkie branże"
                    placeholder="Wszystkie branże"
                    fetch-one-url="/industry"
                    load-url="/industry/list/pagination"
                    query-url="/industry/by-name"
                    list-name="industries"
                    param="name"
                    :custom-params="{withProducts: true, marketId: $store.getters['market/getMarketId'] }"
                />
              </div>

              <div class="col-lg-2 col-12">
              </div>

              <div class="col-lg-2 col-12">
                <ecat-multiselect
                    :set-value="filtering.attributeGroupId"
                    @change="value => filtering.attributeGroupId = value"
                    @select="setToFirstPageAndRefresh()"
                    :can-unselect="true"
                    save-id="id"
                    view-id="name"
                    label="Atrybut"
                    placeholder="Wszystkie atrybuty"
                    fetch-one-url="/attribute/group"
                    load-url="/attribute/group/list/pagination"
                    query-url="/attribute/group/by-name"
                    param="name"
                />
              </div>

              <div class="col-lg-2 col-12">
                <ecat-multiselect
                    :set-value="filtering.depotSectionId"
                    @change="value => filtering.depotSectionId = value"
                    @select="setToFirstPageAndRefresh()"
                    save-id="id"
                    view-id="name"
                    label="Magazyn"
                    reset-option-name="Wszystkie magazyny"
                    placeholder="Wszystkie magazyny"
                    fetch-one-url="/depot/section"
                    load-url="/depot/section/pagination"
                    query-url="/depot/section/by-name"
                    param="name"
                    list-name="depotSections"
                    :custom-params="{ depotId: depotId }"
                    :can-unselect="true"
                />
              </div>

              <div class="col-lg-2 col-12">
                <label>Wartość atrybutu</label>
                <input type="text" v-model="filtering.attribute" class="form-control" />
              </div>

              <div class="col-12">
                <div class="float-right">
                  <a @click="clearFilters" class="btn btn-outline-warning w-sm mb-2 mx-2">Wyczyść filtry</a>
                  <a @click="$refs.table.refresh()" class="btn btn-primary w-sm mb-2">Szukaj</a>
                </div>
              </div>
            </div>

            <ecat-lightbox-gallery :visible="selectedProductImage.visible" :images="selectedProductImage.images" @hide="hideSelectedProductImage" />

              <div class="py-4">
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        {{ $t('table.show') }}&nbsp;
                        <b-form-select v-model="table.perPage" size="sm" :options="table.pageOptions"></b-form-select>&nbsp;{{ $t('table.entries') }}
                      </label>
                    </div>
                  </div>

                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        {{ $t('table.search') }}
                        <b-form-input
                            v-model="table.filter"
                            type="search"
                            class="form-control form-control-sm ml-2"
                            @keyup.enter="$refs.table.refresh()"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>

                <div class="row">
                  <div class="col">
                    <div class="float-right">
                      <label class="d-inline-flex align-items-center mx-2">
                        <b-form-input v-model="inputPage" class="form-control form-control-sm ml-2" />
                      </label>
                      <b-button variant="primary" @click="paginationHelper.setCurrentPage(table, inputPage)">{{ $t('table.go-to-page') }}</b-button>
                    </div>
                  </div>
                </div>

                <div class="row py-3">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <b-pagination v-model="table.currentPage" :total-rows="table.rows" :per-page="table.perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="table-responsive">
                  <b-table
                      ref="table"
                      :items="loadProducts"
                      :fields="getFields()"
                      responsive="sm"
                      :per-page="table.perPage"
                      :current-page="table.currentPage"
                      :sort-by.sync="table.sortBy"
                      :sort-desc.sync="table.sortDesc"
                      :busy.sync="isBusy"
                      :tbody-tr-class="highlightRow"
                      :empty-text="$t('message.no-elements')"
                      :empty-filtered-text="$t('message.no-records')" :show-empty="true">
                    <div slot="table-busy" class="text-center">
                      <h5>{{ $t('message.loading') }}</h5>
                      <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
                    </div>

                    <template #head(element)>
                      <b-form-checkbox :value="isSameProductIds" @change="selectAllProducts"></b-form-checkbox>
                    </template>

                    <template v-slot:cell(element)="{ item }">
                      <div class="custom-control custom-checkbox">
                        <input :value="item.id" v-model="form.productIds" type="checkbox" class="custom-control-input" :id="`check-${item.id}`" :class="form.productIds.includes(item.id) ? 'custom-control-label-selected' : ''" />
                        <label class="custom-control-label" :for="`check-${item.id}`">&nbsp;</label>
                      </div>
                    </template>

                    <template v-slot:cell(productTotalWarehouseStock)="{ item }">
                      {{ getSumDepotSectionStocks(item.id) }}
                    </template>

                    <template v-slot:cell(warehouseEcat)="{ item }">
                      <template v-if="getDepotSectionStocks(item.id)">
                        <div v-for="(depotSectionStock, index) in getDepotSectionStockDepots(item.id)" :key="index">
                          Hurtownia {{ depotSectionStock.depotName }}:
                          <br />
                          <ul v-for="(depotSection, index) in getDepotSectionStocks(item.id).filter(element => element.depotId === depotSectionStock.depotId)" :key="index">
                            <li class="mb-2">
                              {{ depotSection.depotSectionName }}: {{ depotSection.quantity }}
                            </li>
                          </ul>
                        </div>
                      </template>
                    </template>

<!--                    <template v-slot:cell(warehouseProduct)="{ item }">-->
<!--                      <template v-if="item.warehouseProduct">-->
<!--                        <span>{{ item.warehouseProduct }}</span>-->
<!--                      </template>-->
<!--                      <template v-else>-->
<!--                        <span>-</span>-->
<!--                      </template>-->
<!--                    </template>-->

                    <template v-slot:cell(ean)="{ item }">
                      <div :set="ean = item.ean ? jsonUtil.asArray(item.ean) : []">
                        <template v-if="ean && ean.length > 0">
                          <span>{{ ean[0] }}</span>
                          <span v-if="ean.length > 1" class="badge badge-soft-success font-size-11">+{{ ean.length - 1 }} {{  $t('message.others') }}</span>
                        </template>
                        <template v-else>
                          <span>-</span>
                        </template>
                      </div>
                    </template>

                    <template v-slot:cell(image)="{ item }">
                      <template v-if="jsonUtil.asArray(item.images).length > 0">
                        <img :src="jsonUtil.asArray(item.images)[0]" alt="Product Image" class="rounded avatar-lg" @click="selectProductImages(jsonUtil.asArray(item.images))">
                      </template>
                      <template v-else>
                        <span>-</span>
                      </template>
                    </template>

                    <template v-slot:cell(tax)="{ item }">
                      <span> {{item.taxRate }}% </span>
                    </template>

                    <template v-slot:cell(warehouse)="{ item }">
                      <a :href="`/dashboard/admin/warehouse/show/${item.warehouseId}`" target="_blank">{{ item.warehouseName }}</a>
                    </template>

                    <template v-slot:cell(deliveryTime)="{ item }">
                      <span> {{ getProductDeliveryTime(item) }} </span>
                    </template>

                    <template v-slot:cell(shippingCost)="{ item }">
                      <div :set="shippingCostForProduct = getShippingCost(item.productId)" class="custom-row-width">
                        <template v-if="shippingCostForProduct">
                          <p v-for="(shippingCostItem, index) in shippingCostForProduct" :key="index">{{ shippingCostItem.name }}: {{ shippingCostItem.cost.toFixed(2) }}</p>
                        </template>
                        <template v-else>
                          -
                        </template>
                      </div>
                    </template>

                    <template v-slot:cell(expect)="{ item }">
                      <span class="badge font-size-11" :class="productHelper.getAllegroSendStatus(item) ? 'badge-soft-success' : 'badge-soft-danger'">
                        {{ $t(productHelper.getAllegroSendStatus(item) ? 'message.yes' : 'message.no') }}
                      </span>
                    </template>

                    <template v-slot:cell(blockedOnAllegro)="{ item }">
                      <span class="badge font-size-11" :class="item.allegroBlocked ? 'badge-soft-success' : 'badge-soft-danger'">
                        {{ $t(item.allegroBlocked ? 'message.yes' : 'message.no') }}
                      </span>
                    </template>

                    <template v-slot:cell(action)="{ item }">
                      <a :href="`/dashboard/admin/product/show/${item.productId}`" class="clickable-element mr-3 text-primary"><i class="mdi mdi-information font-size-18"></i></a>

<!--                      <a id="details" @click="$refs.productDetailsModal.openModal(item)" class="clickable-element mr-3 text-primary"><i class="mdi mdi-card-account-details font-size-18"></i></a>-->
<!--                      <b-tooltip target="details" placement="left">{{ $t('products.details.title')}}</b-tooltip>-->

<!--                      <a @click="$refs.depotDuplicateProductModal.openModal(item)" id="duplicate" class="clickable-element mr-3 text-primary"><i class="mdi mdi-content-copy font-size-18"></i></a>-->
<!--                      <b-tooltip target="duplicate" placement="left">Duplikuj</b-tooltip>-->
                    </template>
                  </b-table>
                </div>

                <div class="row">
                  <div class="col">
                    <div class="float-left">
                      <p>{{ $t('table.entries-footer', { 'amount': paginationHelper.getElementsAmount(table), 'elements': paginationHelper.getElementsCount(table), 'all': table.totalRows }) }}</p>
                    </div>
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="table.currentPage" :total-rows="table.rows" :per-page="table.perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>

    <product-details-modal ref="productDetailsModal" />

    <b-modal
        id="modal-3"
        v-model="tableColumns.visibleModal"
        title="Wybierz kolumny do pokazania/ukrycia"
        title-class="font-18"
        hide-footer
        @hide="tableColumns.visibleModal = false"
        @esc="tableColumns.visibleModal = false">
      <form>
        <div class="form-group">
          <label class="mx-2"><input v-model="tableColumns.sku" type="checkbox" /> SKU</label>
          <label class="mx-2"><input v-model="tableColumns.image" type="checkbox" /> Zdjęcie</label>
<!--          <label class="mx-2"><input v-model="tableColumns.warehouseProduct" type="checkbox" /> Index hurtowni</label>-->
          <label class="mx-2"><input v-model="tableColumns.name" type="checkbox" /> Nazwa produktu</label>
          <label class="mx-2"><input v-model="tableColumns.categoryName" type="checkbox" /> Nazwa kategorii</label>
          <label class="mx-2"><input v-model="tableColumns.ean" type="checkbox" /> EAN</label>
          <label class="mx-2"><input v-model="tableColumns.profitMargin" type="checkbox" /> Narzut</label>
          <label class="mx-2"><input v-model="tableColumns.shopProfit" type="checkbox" /> Zysk sklep</label>
          <label class="mx-2"><input v-model="tableColumns.logistics" type="checkbox" /> Koszty logistyki i księgowości</label>
          <label class="mx-2"><input v-model="tableColumns.ecatProfit" type="checkbox" /> Zysk ECAT eCommerce (Full Dropshipping)</label>
          <label class="mx-2"><input v-model="tableColumns.priceLow" type="checkbox" /> Cena sklepu netto</label>
          <label class="mx-2"><input v-model="tableColumns.priceHigh" type="checkbox" /> Cena sklepu brutto</label>
          <label class="mx-2"><input v-model="tableColumns.priceWholesaleHigh" type="checkbox" /> Cena hurtowni netto</label>
          <label class="mx-2"><input v-model="tableColumns.expect" type="checkbox" /> Dozwolony do sprzedaży na Allegro</label>
          <label class="mx-2"><input v-model="tableColumns.blockedOnAllegro" type="checkbox" /> Czy zablokowana wysyłka na Allegro</label>
          <label class="mx-2"><input v-model="tableColumns.totalWarehouseStock" type="checkbox" /> Ilość sztuk</label>
          <label class="mx-2"><input v-model="tableColumns.warehouse" type="checkbox" /> Hurtownia</label>
          <label class="mx-2"><input v-model="tableColumns.warehouseEcat" type="checkbox" /> Magazyn ECAT</label>
          <label class="mx-2"><input v-model="tableColumns.tax" type="checkbox" /> Stawka podatku</label>
          <label class="mx-2"><input v-model="tableColumns.deliveryTime" type="checkbox" /> Czas dostawy</label>
          <label class="mx-2"><input v-model="tableColumns.industry" type="checkbox" /> Branża</label>
          <label class="mx-2"><input v-model="tableColumns.shippingCost" type="checkbox" /> Koszt dostawy</label>
        </div>
      </form>
    </b-modal>

    <change-oversize-modal ref="changeOversizeModal" />

  </Layout>
</template>